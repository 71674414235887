import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const SlidingNotices = ({ notices }) => {
  const [currentNotice, setCurrentNotice] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNotice((prev) => (prev + 1) % notices.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [notices.length]);

  if (notices.length === 0) {
    return null; // Don't render anything if there are no notices
  }

  return (
    <div className="bg-secondary py-4">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold text-white mb-4">{t('latestNotices')}</h2>
        <div className="relative h-32 overflow-hidden">
          {notices.map((notice, index) => (
            <div
              key={notice.id}
              className={`absolute w-full transition-all duration-500 ease-in-out ${
                index === currentNotice ? 'top-0 opacity-100' : 'top-full opacity-0'
              }`}
            >
              <div className="bg-white shadow-md rounded-lg p-4">
                <h3 className="text-lg font-semibold text-primary mb-2">{notice.title}</h3>
                <p className="text-sm text-gray-600 mb-2">{notice.date}</p>
                <p className="text-gray-800">{notice.content}</p>
                {notice.pdf_link && (
                  <a href={notice.pdf_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    View PDF
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SlidingNotices;