// NoticeManagement.js
import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import EditNoticeModal from './EditNoticeModal';

const NoticeManagement = ({ setMessage }) => {
    const [noticeTitle, setNoticeTitle] = useState('');
    const [noticeContent, setNoticeContent] = useState('');
    const [noticeDate, setNoticeDate] = useState('');
    const [noticePdfLink, setNoticePdfLink] = useState('');
    const [notices, setNotices] = useState([]);
    const [editingNotice, setEditingNotice] = useState(null);

    useEffect(() => {
        fetchNotices();
    }, []);

    const fetchNotices = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/get_notices`);
            const data = await response.json();
            setNotices(data);
        } catch (error) {
            console.error('Error fetching notices:', error);
            setMessage('Failed to fetch notices');
        }
    };

    const handleCreateNotice = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('adminToken');

        try {
            const response = await fetch(`${API_BASE_URL}/admin/create_notice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title: noticeTitle,
                    content: noticeContent,
                    date: noticeDate,
                    pdf_link: noticePdfLink,
                }),
            });

            if (response.ok) {
                setMessage('Notice created successfully');
                fetchNotices();
                setNoticeTitle('');
                setNoticeContent('');
                setNoticeDate('');
                setNoticePdfLink('');
            } else {
                const errorData = await response.json();
                setMessage(`Failed to create notice: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setMessage(`An error occurred: ${error.message}`);
        }
    };

    const handleSaveEdit = async (updatedNotice) => {
        const token = localStorage.getItem('adminToken');
        const url = `${API_BASE_URL}/admin/edit_notice/${updatedNotice.id}`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedNotice),
            });

            if (response.ok) {
                setMessage('Notice updated successfully');
                fetchNotices();
                setEditingNotice(null);
            } else {
                const errorData = await response.json();
                setMessage(`Failed to update notice: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error updating notice:', error);
            setMessage(`An error occurred while updating notice`);
        }
    };

    return (
        <>
            <form onSubmit={handleCreateNotice} className="space-y-6">
                <input
                    type="text"
                    value={noticeTitle}
                    onChange={(e) => setNoticeTitle(e.target.value)}
                    placeholder="Title"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                />
                <textarea
                    value={noticeContent}
                    onChange={(e) => setNoticeContent(e.target.value)}
                    placeholder="Content"
                    rows="4"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                ></textarea>
                <input
                    type="date"
                    value={noticeDate}
                    onChange={(e) => setNoticeDate(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                />
                <input
                    type="url"
                    value={noticePdfLink}
                    onChange={(e) => setNoticePdfLink(e.target.value)}
                    placeholder="PDF Link (optional)"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                />
                <button
                    type="submit"
                    className="w-full py-3 bg-indigo-500 text-white rounded-lg"
                >
                    Create Notice
                </button>
            </form>
            <div className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Existing Notices</h2>
                {notices.map((notice) => (
                    <div key={notice.id} className="border rounded-lg p-4 mb-4">
                        <h3 className="text-xl font-semibold">{notice.title}</h3>
                        <p className="text-gray-600">{notice.content}</p>
                        <p className="text-sm text-gray-500 mt-2">Date: {notice.date}</p>
                        {notice.pdf_link && (
                            <a
                                href={notice.pdf_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                View PDF
                            </a>
                        )}
                        <button
                            onClick={() => setEditingNotice(notice)}
                            className="mt-2 px-4 py-2 bg-indigo-500 text-white rounded"
                        >
                            Edit
                        </button>
                    </div>
                ))}
            </div>

            {editingNotice && (
                <EditNoticeModal
                    notice={editingNotice}
                    onClose={() => setEditingNotice(null)}
                    onSave={handleSaveEdit}
                />
            )}
        </>
    );
};

export default NoticeManagement;
