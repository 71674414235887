import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Home, Info, BookOpen, Activity, Bell, Users, 
  Video, Mail, LogOut, LogIn, Menu, X, Globe, 
  ChevronDown, Sparkles
} from 'lucide-react';
import { API_BASE_URL } from '../config';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [logoUrl, setLogoUrl] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Navigation items with icons
  const navItems = [
    { key: 'home', path: '/', icon: Home },
    { key: 'about', path: '/about', icon: Info },
    { key: 'classes', path: '/classes', icon: BookOpen },
    { key: 'activities', path: '/activities', icon: Activity },
    { key: 'notices', path: '/notices', icon: Bell },
    { key: 'teachers', path: '/teachers', icon: Users },
    { key: 'online_classes', path: '/online_classes', icon: Video },
    { key: 'contact', path: '/contact', icon: Mail },
  ];

  // Check scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Fetch logo
  useEffect(() => {
    fetch(`${API_BASE_URL}/get_images/logo`)
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setLogoUrl(`${API_BASE_URL}/uploads/${data[0].filename}`);
        }
      })
      .catch(error => console.error('Error fetching logo:', error));
  }, []);

  // Check admin login
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    setIsAdminLoggedIn(!!token);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAdminLoggedIn(false);
    navigate('/');
  };

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled 
          ? 'bg-white/90 backdrop-blur-md text-gray-800 shadow-lg'
          : 'bg-gradient-to-r from-primary to-green-600 text-white'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-11">
          {/* Logo and School Name */}
          <motion.div 
            className="flex items-center space-x-3"
            whileHover={{ scale: 1.05 }}
          >
            {logoUrl && (
              <div className="relative">
                <motion.img
                  src={logoUrl}
                  alt="School Logo"
                  className="w-10 h-10 rounded-full border-2 border-current shadow-lg"
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.8 }}
                />
                <motion.div
                  className="absolute -inset-1 rounded-full"
                  animate={{ 
                    boxShadow: ['0 0 0 0 rgba(59, 130, 246, 0.4)', '0 0 0 10px rgba(59, 130, 246, 0)'],
                  }}
                  transition={{ 
                    duration: 1.5,
                    repeat: Infinity,
                  }}
                />
              </div>
            )}
            <motion.h1 
              className="text-xl font-bold hidden md:block"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              {t('schoolName')}
            </motion.h1>
          </motion.div>

          {/* Desktop Navigation */}
          <nav className="hidden md:block">
            <ul className="flex items-center space-x-1">
              {navItems.map((item) => {
                const Icon = item.icon;
                return (
                  <motion.li key={item.key} whileHover={{ y: -2 }}>
                    <Link
                      to={item.path}
                      className={`flex items-center space-x-1 px-3 py-2 rounded-full text-sm transition-colors duration-300 ${
                        location.pathname === item.path
                          ? 'bg-white/20 backdrop-blur-sm'
                          : 'hover:bg-white/10'
                      }`}
                    >
                      <Icon size={16} />
                      <span>{t(item.key)}</span>
                    </Link>
                  </motion.li>
                );
              })}
              
              {/* Admin Links */}
              {isAdminLoggedIn ? (
                <motion.div className="flex items-center space-x-2">
                  <Link
                    to="/admin/dashboard"
                    className="flex items-center space-x-1 px-3 py-2 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
                  >
                    <Sparkles size={16} />
                    <span>Admin</span>
                  </Link>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleLogout}
                    className="flex items-center space-x-1 px-3 py-2 rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors"
                  >
                    <LogOut size={16} />
                    <span>Logout</span>
                  </motion.button>
                </motion.div>
              ) : (
                <motion.li whileHover={{ scale: 1.05 }}>
                  <Link
                    to="/admin/login"
                    className="flex items-center space-x-1 px-3 py-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
                  >
                    <LogIn size={16} />
                    <span>Admin</span>
                  </Link>
                </motion.li>
              )}

              {/* Language Switcher */}
              <motion.div 
                className="relative group"
                whileHover={{ scale: 1.05 }}
              >
                <button className="flex items-center space-x-1 px-3 py-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20">
                  <Globe size={16} />
                  <ChevronDown size={14} />
                </button>
                <div className="absolute right-0 mt-2 py-2 w-32 bg-white rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                  {['bn', 'en'].map((lang) => (
                    <button
                      key={lang}
                      onClick={() => changeLanguage(lang)}
                      className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 transition-colors"
                    >
                      {lang === 'bn' ? 'বাংলা' : 'English'}
                    </button>
                  ))}
                </div>
              </motion.div>
            </ul>
          </nav>

          {/* Mobile Menu Button */}
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-white/10"
          >
            {isMenuOpen ? <X /> : <Menu />}
          </motion.button>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.nav
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="md:hidden overflow-hidden"
            >
              <motion.ul
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                className="py-4 space-y-1"
              >
                {navItems.map((item) => {
                  const Icon = item.icon;
                  return (
                    <motion.li
                      key={item.key}
                      whileHover={{ x: 10 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <Link
                        to={item.path}
                        onClick={() => setIsMenuOpen(false)}
                        className={`flex items-center space-x-2 px-4 py-3 rounded-lg ${
                          location.pathname === item.path
                            ? 'bg-white/20 backdrop-blur-sm'
                            : 'hover:bg-white/10'
                        }`}
                      >
                        <Icon size={18} />
                        <span>{t(item.key)}</span>
                      </Link>
                    </motion.li>
                  );
                })}

                {/* Mobile Admin Links */}
                {isAdminLoggedIn ? (
                  <>
                    <motion.li whileHover={{ x: 10 }}>
                      <Link
                        to="/admin/dashboard"
                        onClick={() => setIsMenuOpen(false)}
                        className="flex items-center space-x-2 px-4 py-3 rounded-lg bg-blue-500 text-white"
                      >
                        <Sparkles size={18} />
                        <span>Admin Dashboard</span>
                      </Link>
                    </motion.li>
                    <motion.li whileHover={{ x: 10 }}>
                      <button
                        onClick={() => {
                          handleLogout();
                          setIsMenuOpen(false);
                        }}
                        className="w-full flex items-center space-x-2 px-4 py-3 rounded-lg bg-red-500 text-white"
                      >
                        <LogOut size={18} />
                        <span>Logout</span>
                      </button>
                    </motion.li>
                  </>
                ) : (
                  <motion.li whileHover={{ x: 10 }}>
                    <Link
                      to="/admin/login"
                      onClick={() => setIsMenuOpen(false)}
                      className="flex items-center space-x-2 px-4 py-3 rounded-lg bg-white/10 backdrop-blur-sm"
                    >
                      <LogIn size={18} />
                      <span>Admin Login</span>
                    </Link>
                  </motion.li>
                )}

                {/* Mobile Language Switcher */}
                <div className="flex justify-center space-x-2 pt-4">
                  {['bn', 'en'].map((lang) => (
                    <motion.button
                      key={lang}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => {
                        changeLanguage(lang);
                        setIsMenuOpen(false);
                      }}
                      className="px-4 py-2 rounded-full bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-colors"
                    >
                      {lang === 'bn' ? 'বাংলা' : 'English'}
                    </motion.button>
                  ))}
                </div>
              </motion.ul>
            </motion.nav>
          )}
        </AnimatePresence>
      </div>
      <div style={{ marginBottom: '1px' }}></div>
    </motion.header>
  );
};

export default Header;
