import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditFooterContact = () => {
    const [contactDetails, setContactDetails] = useState({
        address: '',
        phone: '',
        email: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
    });
    const [message, setMessage] = useState('');

    // Backend base URL
    const API_BASE_URL = 'https://gangapurschool.pythonanywhere.com';

    // Fetch footer contact details from the backend
    useEffect(() => {
        const fetchFooterContact = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/admin/get_footer_contact`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('adminToken')}`, // Include the admin token
                    },
                });
                if (response.data) {
                    setContactDetails(response.data); // Populate the form with fetched data
                }
            } catch (error) {
                console.error('Error fetching footer contact details:', error);
                setMessage('Failed to fetch footer contact details.');
            }
        };
        fetchFooterContact();
    }, []);

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Save updated footer contact details
    const handleSave = () => {
        axios
            .put(`${API_BASE_URL}/admin/edit_footer_contact`, contactDetails, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminToken')}`, // Include the admin token
                },
            })
            .then((response) => {
                setMessage(response.data.message || 'Footer contact updated successfully.');
            })
            .catch((error) => {
                console.error('Error saving footer contact details:', error);
                setMessage('Failed to update footer contact details.');
            });
    };

    return (
        <div className="p-8">
            <h2 className="text-2xl font-bold text-center mb-6">Edit Footer Contact</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Address</label>
                    <input
                        type="text"
                        name="address"
                        value={contactDetails.address}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone</label>
                    <input
                        type="text"
                        name="phone"
                        value={contactDetails.phone}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={contactDetails.email}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Facebook</label>
                    <input
                        type="text"
                        name="facebook"
                        value={contactDetails.facebook}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Twitter</label>
                    <input
                        type="text"
                        name="twitter"
                        value={contactDetails.twitter}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">LinkedIn</label>
                    <input
                        type="text"
                        name="linkedin"
                        value={contactDetails.linkedin}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Instagram</label>
                    <input
                        type="text"
                        name="instagram"
                        value={contactDetails.instagram}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
            <div className="mt-6 text-center">
                <button
                    onClick={handleSave}
                    className="px-6 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 transition"
                >
                    Save
                </button>
            </div>
            {message && (
                <div className={`mt-4 p-4 rounded-md ${message.includes('Failed') ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                    {message}
                </div>
            )}
        </div>
    );
};

export default EditFooterContact;
