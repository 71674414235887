// TeacherManagement.js
import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import EditTeacherModal from './EditTeacherModal';

const TeacherManagement = ({ setMessage }) => {
    const [teacherName, setTeacherName] = useState('');
    const [teacherImage, setTeacherImage] = useState(null);
    const [teacherContact, setTeacherContact] = useState('');
    const [teachers, setTeachers] = useState([]);
    const [editingTeacher, setEditingTeacher] = useState(null);

    useEffect(() => {
        fetchTeachers();
    }, []);

    const fetchTeachers = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/get_teachers`);
            const data = await response.json();
            setTeachers(data);
        } catch (error) {
            console.error('Error fetching teachers:', error);
            setMessage('Failed to fetch teachers');
        }
    };

    const handleAddTeacher = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', teacherName);
        formData.append('image', teacherImage);
        formData.append('contact_number', teacherContact);

        const token = localStorage.getItem('adminToken');

        try {
            const response = await fetch(`${API_BASE_URL}/admin/add_teacher`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                setMessage('Teacher added successfully');
                fetchTeachers();
                setTeacherName('');
                setTeacherImage(null);
                setTeacherContact('');
            } else {
                const errorData = await response.json();
                setMessage(`Failed to add teacher: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setMessage(`An error occurred: ${error.message}`);
        }
    };

    const handleSaveEdit = async (updatedTeacher) => {
        const token = localStorage.getItem('adminToken');
        const url = `${API_BASE_URL}/admin/edit_teacher/${updatedTeacher.get('id')}`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: updatedTeacher,
            });

            if (response.ok) {
                setMessage('Teacher updated successfully');
                fetchTeachers();
                setEditingTeacher(null);
            } else {
                const errorData = await response.json();
                setMessage(`Failed to update teacher: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error updating teacher:', error);
            setMessage(`An error occurred while updating teacher`);
        }
    };

    return (
        <>
            <form onSubmit={handleAddTeacher} className="space-y-6">
                <input
                    type="text"
                    value={teacherName}
                    onChange={(e) => setTeacherName(e.target.value)}
                    placeholder="Teacher Name"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                />
                <input
                    type="file"
                    onChange={(e) => setTeacherImage(e.target.files[0])}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    accept="image/*"
                />
                <input
                    type="tel"
                    value={teacherContact}
                    onChange={(e) => setTeacherContact(e.target.value)}
                    placeholder="Contact Number"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                />
                <button
                    type="submit"
                    className="w-full py-3 bg-indigo-500 text-white rounded-lg"
                >
                    Add Teacher
                </button>
            </form>
            <div className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Existing Teachers</h2>
                {teachers.map((teacher) => (
                    <div key={teacher.id} className="border rounded-lg p-4 mb-4 flex items-center">
                        {teacher.image_url && (
                            <img
                                src={`${API_BASE_URL}${teacher.image_url}`}
                                alt={teacher.name}
                                className="w-16 h-16 rounded-full mr-4 object-cover"
                            />
                        )}
                        <div>
                            <h3 className="text-xl font-semibold">{teacher.name}</h3>
                            <p className="text-gray-600">{teacher.contact_number}</p>
                            <button
                                onClick={() => setEditingTeacher(teacher)}
                                className="mt-2 px-4 py-2 bg-indigo-500 text-white rounded"
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {editingTeacher && (
                <EditTeacherModal
                    teacher={editingTeacher}
                    onClose={() => setEditingTeacher(null)}
                    onSave={handleSaveEdit}
                />
            )}
        </>
    );
};

export default TeacherManagement;
