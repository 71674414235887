import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import './App.css';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import NoticesPage from './components/NoticesPage';
import TeachersPage from './components/TeachersPage';
import OnlineClassesPage from './components/OnlineClassesPage';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';

const PrivateRoute = ({ children }) => {
  const adminToken = localStorage.getItem('adminToken');
  return adminToken ? children : <Navigate to="/admin/login" />;
};

// Loading Component
const LoadingScreen = () => (
  <div className="fixed inset-0 bg-gradient-to-br from-blue-600 to-purple-700 flex items-center justify-center z-50">
    <motion.div
      animate={{ 
        scale: [1, 1.2, 1],
        rotate: [0, 360]
      }}
      transition={{ 
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className="text-white text-6xl"
    >
      <Sparkles className="w-16 h-16" />
    </motion.div>
  </div>
);

// Main Layout Component
const MainLayout = ({ isLoading }) => {
  if (isLoading) return <LoadingScreen />;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Header />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/notices" element={<NoticesPage />} />
          <Route path="/teachers" element={<TeachersPage />} />
          <Route path="/online_classes" element={<OnlineClassesPage />} />
          {/* Add other routes here as you create more pages */}
        </Routes>
      </main>
      <Footer />
    </motion.div>
  );
};

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate initial loading time to ensure all components are ready
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <AnimatePresence mode="wait">
        <div className="App flex flex-col min-h-screen">
          <Routes>
            <Route 
              path="/admin/login" 
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <AdminLogin />
                </motion.div>
              } 
            />
            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <AdminDashboard />
                  </motion.div>
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={<MainLayout isLoading={isLoading} />}
            />
          </Routes>
        </div>
      </AnimatePresence>
    </Router>
  );
}

export default App;