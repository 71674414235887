// EditOnlineClassModal.js
import React, { useState } from 'react';
import { motion } from 'framer-motion';

const EditOnlineClassModal = ({ onlineClass, onClose, onSave }) => {
    const [title, setTitle] = useState(onlineClass.title);
    const [time, setTime] = useState(
        // Ensure time is in the correct format for datetime-local input
        new Date(onlineClass.time).toISOString().slice(0, 16)
    );
    const [liveLink, setLiveLink] = useState(onlineClass.live_link || '');
    const [recordingLink, setRecordingLink] = useState(onlineClass.recording_link || '');

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedClass = {
            id: onlineClass.id, // Include the id
            title,
            time,
            live_link: liveLink,
            recording_link: recordingLink,
        };
        onSave(updatedClass);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <motion.div
                className="bg-white rounded-lg p-6 w-full max-w-md"
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
            >
                <h2 className="text-xl font-bold mb-4">Edit Online Class</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Class Title"
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                    <input
                        type="datetime-local"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                    <input
                        type="url"
                        value={liveLink}
                        onChange={(e) => setLiveLink(e.target.value)}
                        placeholder="Live Class Link"
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <input
                        type="url"
                        value={recordingLink}
                        onChange={(e) => setRecordingLink(e.target.value)}
                        placeholder="Recording Link"
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </motion.div>
        </div>
    );
};

export default EditOnlineClassModal;
