import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import ImageUpload from './ImageUpload';
import NoticeManagement from './NoticeManagement';
import TeacherManagement from './TeacherManagement';
import ClassManagement from './ClassManagement';
import EditFooterContact from './editFooterContact';

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('upload');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        navigate('/admin/login');
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-100 via-pink-100 to-rose-100 p-8">
            <div className="max-w-6xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
                <div className="flex justify-between items-center bg-gradient-to-r from-purple-600 to-indigo-600 p-6">
                    <h1 className="text-3xl font-bold text-white">Admin Dashboard</h1>
                    <button
                        onClick={handleLogout}
                        className="px-6 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                    >
                        Logout
                    </button>
                </div>

                <div className="flex border-b border-gray-200">
                    {['upload', 'notice', 'teacher', 'class', 'footer'].map((tab) => (
                        <button
                            key={tab}
                            className={`flex-1 py-4 px-6 text-center ${
                                activeTab === tab ? 'bg-indigo-100 text-indigo-600 font-semibold' : 'text-gray-500 hover:text-indigo-500'
                            }`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                    ))}
                </div>

                <div className="p-8">
                    {activeTab === 'upload' && <ImageUpload setMessage={setMessage} />}
                    {activeTab === 'notice' && <NoticeManagement setMessage={setMessage} />}
                    {activeTab === 'teacher' && <TeacherManagement setMessage={setMessage} />}
                    {activeTab === 'class' && <ClassManagement setMessage={setMessage} />}
                    {activeTab === 'footer' && <EditFooterContact setMessage={setMessage} />}
                </div>

                <AnimatePresence>
                    {message && (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            className="mt-6 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 rounded-r-lg"
                        >
                            <p className="font-bold">Message</p>
                            <p>{message}</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default AdminDashboard;
