import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  MapPin, Phone, Mail, Globe, ArrowUpCircle, 
  Facebook, Twitter, Linkedin, Instagram, Youtube,
  BookOpen, Users, Heart, School, Star, Calendar,
  MessageCircle, ExternalLink, ChevronRight
} from 'lucide-react';
import { API_BASE_URL } from '../config';

const Footer = () => {
  const { t } = useTranslation();
  const [footerContact, setFooterContact] = useState({
    address: '',
    phone: '',
    email: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
    youtube: ''
  });
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [hoveredIcon, setHoveredIcon] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contactRes, logoRes] = await Promise.all([
          fetch(`${API_BASE_URL}/admin/get_footer_contact`),
          fetch(`${API_BASE_URL}/get_images/logo`)
        ]);

        const contactData = await contactRes.json();
        const logoData = await logoRes.json();

        if (contactData) {
          setFooterContact(contactData);
        }

        if (logoData && logoData.length > 0) {
          setLogoUrl(`${API_BASE_URL}/uploads/${logoData[0].filename}`);
        }
      } catch (error) {
        console.error('Error fetching footer data:', error);
      }
    };

    fetchData();

    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Updated quickLinks array with Teachers instead of Admission
  const quickLinks = [
    { text: t('হোম'), path: '/', icon: Globe },
    { text: t('আমাদের সম্পর্কে'), path: '/about', icon: Users },
    { text: t('নোটিশ'), path: '/notices', icon: MessageCircle },
    { text: t('কার্যক্রম'), path: '/activities', icon: Calendar },
    { text: t('শিক্ষকবৃন্দ'), path: '/teachers', icon: Users }, // Changed from Admission to Teachers
    { text: t('যোগাযোগ'), path: '/contact', icon: Mail }
  ];

  const socialLinks = [
    { name: 'facebook', icon: Facebook, color: 'bg-blue-500', link: footerContact.facebook },
    { name: 'twitter', icon: Twitter, color: 'bg-sky-500', link: footerContact.twitter },
    { name: 'youtube', icon: Youtube, color: 'bg-red-500', link: footerContact.youtube },
    { name: 'linkedin', icon: Linkedin, color: 'bg-blue-700', link: footerContact.linkedin },
    { name: 'instagram', icon: Instagram, color: 'bg-pink-500', link: footerContact.instagram }
  ];

  return (
    <footer className="relative bg-gradient-to-b from-gray-50 to-gray-100 border-t border-gray-200 overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-10 left-1/4 w-72 h-72 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob" />
        <div className="absolute -top-10 right-1/4 w-72 h-72 bg-green-200 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000" />
      </div>

      <div className="relative container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* School Info */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="flex flex-col items-center md:items-start"
          >
            <div className="relative mb-6">
              {logoUrl ? (
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="relative"
                >
                  <motion.div
                    className="absolute inset-0 bg-gradient-to-r from-blue-500 to-green-500 rounded-full blur-lg opacity-50"
                    animate={{
                      scale: [1, 1.2, 1],
                      rotate: [0, 180, 360],
                    }}
                    transition={{
                      duration: 10,
                      repeat: Infinity,
                      ease: "linear"
                    }}
                  />
                  <img
                    src={logoUrl}
                    alt="School Logo"
                    className="relative w-24 h-24 rounded-full border-2 border-white shadow-lg"
                  />
                </motion.div>
              ) : (
                <School className="w-24 h-24 text-blue-600" />
              )}
            </div>
            <h3 className="text-2xl font-bold mb-2 text-center md:text-left text-gray-800">
              {t('গঙ্গাপুর সরকারি প্রাথমিক বিদ্যালয়')}
            </h3>
            <p className="text-gray-600 text-sm text-center md:text-left">
              {t('শিক্ষা নিয়ে গড়ব দেশ, শেখ হাসিনার বাংলাদেশ')}
            </p>
          </motion.div>

          {/* Quick Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <h4 className="text-lg font-semibold mb-6 text-gray-800 flex items-center">
              <Heart className="w-5 h-5 text-red-500 mr-2" />
              {t('দ্রুত লিঙ্ক')}
            </h4>
            <ul className="space-y-3">
              {quickLinks.map((link) => (
                <motion.li 
                  key={link.path}
                  whileHover={{ x: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <Link
                    to={link.path}
                    className="flex items-center text-gray-600 hover:text-blue-600 transition-colors group"
                  >
                    <link.icon className="w-4 h-4 mr-2 transition-transform group-hover:scale-110" />
                    <span className="relative">
                      {link.text}
                      <motion.span
                        className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 origin-left transition-transform"
                        whileHover={{ scaleX: 1 }}
                      />
                    </span>
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Contact Info */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <h4 className="text-lg font-semibold mb-6 text-gray-800 flex items-center">
              <Mail className="w-5 h-5 text-blue-500 mr-2" />
              {t('যোগাযোগ')}
            </h4>
            <ul className="space-y-4">
              <motion.li 
                className="flex items-start group"
                whileHover={{ x: 5 }}
              >
                <div className="p-2 rounded-lg bg-red-100 group-hover:bg-red-200 transition-colors">
                  <MapPin className="w-5 h-5 text-red-500" />
                </div>
                <p className="ml-3 text-gray-600 mt-1">
                  {footerContact.address || t('গঙ্গাপুর, পাবনা')}
                </p>
              </motion.li>
              <motion.li 
                className="flex items-center group"
                whileHover={{ x: 5 }}
              >
                <div className="p-2 rounded-lg bg-green-100 group-hover:bg-green-200 transition-colors">
                  <Phone className="w-5 h-5 text-green-500" />
                </div>
                <p className="ml-3 text-gray-600">
                  {footerContact.phone || '+880 1234-567890'}
                </p>
              </motion.li>
              <motion.li 
                className="flex items-center group"
                whileHover={{ x: 5 }}
              >
                <div className="p-2 rounded-lg bg-blue-100 group-hover:bg-blue-200 transition-colors">
                  <Mail className="w-5 h-5 text-blue-500" />
                </div>
                <p className="ml-3 text-gray-600">
                  {footerContact.email || 'info@gongapurprimary.edu.bd'}
                </p>
              </motion.li>
            </ul>
          </motion.div>

          {/* Social Links */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            <h4 className="text-lg font-semibold mb-6 text-gray-800 flex items-center">
              <Globe className="w-5 h-5 text-purple-500 mr-2" />
              {t('সোশ্যাল মিডিয়া')}
            </h4>
            <div className="flex flex-wrap gap-4">
              {socialLinks.map((social) => social.link && (
                <motion.a
                  key={social.name}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`relative p-3 rounded-lg ${social.color} text-white overflow-hidden`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onHoverStart={() => setHoveredIcon(social.name)}
                  onHoverEnd={() => setHoveredIcon(null)}
                >
                  <social.icon className="w-5 h-5 relative z-10" />
                  <motion.div
                    className="absolute inset-0 bg-white"
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{
                      scale: hoveredIcon === social.name ? 1.5 : 0,
                      opacity: hoveredIcon === social.name ? 0.2 : 0,
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.a>
              ))}
            </div>
          </motion.div>
        </div>

        {/* Copyright */}
        <motion.div 
          className="mt-12 pt-8 border-t border-gray-200 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          <p className="text-gray-600">
            &copy; {new Date().getFullYear()} {t('গঙ্গাপুর সরকারি প্রাথমিক বিদ্যালয়')}। 
            {t('সর্বস্বত্ব সংরক্ষিত')}।
          </p>
        </motion.div>
      </div>

      {/* Scroll to Top */}
      <motion.button
        onClick={scrollToTop}
        initial={{ scale: 0 }}
        animate={{ 
          scale: showScrollTop ? 1 : 0,
          opacity: showScrollTop ? 1 : 0
        }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="fixed bottom-8 right-8 p-4 rounded-full bg-gradient-to-r from-blue-500 to-green-500 text-white shadow-lg"
      >
        <ArrowUpCircle className="w-6 h-6" />
      </motion.button>
    </footer>
  );
};

export default Footer;