import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform, useSpring, AnimatePresence } from 'framer-motion';
import { 
  BookOpen, Users, Award, ChevronDown, 
  Sparkles, Sun, Moon, Bell, Calendar,
  X, ArrowRight, Star, Clock, ChevronRight,
  MapPin, Phone, Mail, Globe, Heart, Share2,
  Video, MessageCircle, Send, Hash
} from 'lucide-react';
import SlidingNotices from './SlidingNotices';
import { API_BASE_URL } from '../config';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [notices, setNotices] = useState([]);
  const [categoryImages, setCategoryImages] = useState({
    quality_education: '',
    extracurricular: '',
    experienced_teachers: ''
  });
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showNotices, setShowNotices] = useState(false);
  const heroRef = useRef(null);
  const { scrollYProgress } = useScroll();
  const [isLoading, setIsLoading] = useState(true);

  // Parallax and animation values
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);
  const opacity = useTransform(scrollYProgress, [0, 0.3], [1, 0]);
  const springScrollY = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);

    Promise.all([
      fetch(`${API_BASE_URL}/get_images/hero`).then(res => res.json()),
      fetch(`${API_BASE_URL}/get_notices`).then(res => res.json()),
      Promise.all(['quality_education', 'extracurricular', 'experienced_teachers']
        .map(category => 
          fetch(`${API_BASE_URL}/get_images/${category}`).then(res => res.json())
        ))
    ]).then(([heroData, noticesData, categoryData]) => {
      if (heroData.length > 0) {
        setHeroImageUrl(`${API_BASE_URL}/uploads/${heroData[0].filename}`);
      }
      setNotices(noticesData);
      categoryData.forEach((data, index) => {
        const category = ['quality_education', 'extracurricular', 'experienced_teachers'][index];
        if (data.length > 0) {
          setCategoryImages(prev => ({
            ...prev,
            [category]: `${API_BASE_URL}/uploads/${data[0].filename}`
          }));
        }
      });
    }).catch(error => console.error('Error fetching data:', error));
  }, []);

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-gradient-to-br from-blue-600 to-purple-700 flex items-center justify-center">
        <motion.div
          className="relative"
          animate={{ 
            rotate: [0, 360],
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            ease: "linear"
          }}
        >
          <motion.div
            animate={{ 
              scale: [1, 1.2, 1],
              opacity: [0.5, 1, 0.5]
            }}
            transition={{ 
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="absolute -inset-6 bg-white/20 rounded-full blur-xl"
          />
          <Sparkles className="w-16 h-16 text-white" />
        </motion.div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen transition-colors duration-500 ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
      {/* Notices Modal */}
      <AnimatePresence>
        {showNotices && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
            onClick={() => setShowNotices(false)}
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className={`w-full max-w-2xl ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-2xl overflow-hidden`}
              onClick={e => e.stopPropagation()}
            >
              <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                <h3 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                  Latest Updates
                </h3>
                <button
                  onClick={() => setShowNotices(false)}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              <div className="max-h-[60vh] overflow-y-auto">
                {notices.map((notice, index) => (
                  <motion.div
                    key={notice.id}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`p-4 border-b border-gray-200 hover:${isDarkMode ? 'bg-gray-700' : 'bg-gray-50'} cursor-pointer`}
                    onClick={() => navigate('/notices')}
                  >
                    <div className="flex items-start space-x-4">
                      <div className={`p-3 rounded-full ${isDarkMode ? 'bg-gray-700' : 'bg-blue-100'}`}>
                        <Star className="w-6 h-6 text-blue-500" />
                      </div>
                      <div className="flex-1">
                        <h4 className={`font-semibold mb-1 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
                          {notice.title}
                        </h4>
                        <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                          {notice.description}
                        </p>
                        <div className="flex items-center mt-2 text-sm text-gray-500">
                          <Clock className="w-4 h-4 mr-1" />
                          <span>{notice.date}</span>
                        </div>
                      </div>
                      <ChevronRight className="w-5 h-5 text-gray-400" />
                    </div>
                  </motion.div>
                ))}
              </div>
              <div className="p-4 bg-gray-50 flex justify-end">
                <button
                  onClick={() => navigate('/notices')}
                  className="flex items-center space-x-2 text-blue-600 hover:text-blue-700 font-medium"
                >
                  <span>View All Notices</span>
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Theme Toggle */}
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setIsDarkMode(!isDarkMode)}
        className="fixed top-4 right-4 z-50 p-3 rounded-full bg-white/10 backdrop-blur-md shadow-lg"
      >
        {isDarkMode ? <Sun className="text-yellow-400" /> : <Moon className="text-gray-800" />}
      </motion.button>

      {/* Hero Section */}
      <motion.section 
        ref={heroRef}
        className="relative h-screen flex items-center justify-center overflow-hidden"
        style={{ y }}
      >
        <motion.div 
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: `url(${heroImageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black/80" />
        </motion.div>

        <div className="absolute inset-0">
          <div className="h-full w-full flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center z-10 px-4"
            >
              <motion.h1 
                className="text-8xl font-bold mb-6 text-white"
                animate={{ 
                  textShadow: [
                    "0 0 20px rgba(255,255,255,0.5)",
                    "0 0 40px rgba(255,255,255,0.5)",
                    "0 0 20px rgba(255,255,255,0.5)"
                  ]
                }}
                transition={{ duration: 2, repeat: Infinity }}
              >
                {t('schoolName')}
              </motion.h1>
              <p className="text-4xl mb-12 text-gray-200 font-light">
                {t('schoolSlogan')}
              </p>
              <Link 
                to="/about"
                className="group relative inline-flex items-center justify-center"
              >
                <span className="absolute inset-0 w-full h-full transition duration-200 ease-out transform translate-x-1 translate-y-1 bg-blue-700 group-hover:-translate-x-0 group-hover:-translate-y-0"></span>
                <span className="absolute inset-0 w-full h-full bg-white border-2 border-blue-700 group-hover:bg-blue-700"></span>
                <span className="relative text-blue-700 group-hover:text-white px-8 py-4 text-xl font-bold">
                  {t('learnMore')}
                </span>
              </Link>
            </motion.div>
          </div>
        </div>

        <motion.div 
          animate={{ 
            y: [0, 10, 0],
            opacity: [0.5, 1, 0.5]
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white"
        >
          <ChevronDown className="w-12 h-12" />
        </motion.div>
      </motion.section>

      {/* Floating Notice Badge */}
      <motion.div
        initial={{ x: '-100%' }}
        animate={{ x: 0 }}
        whileHover={{ scale: 1.05 }}
        className="fixed left-0 top-1/4 z-40"
      >
        <motion.button
          onClick={() => setShowNotices(true)}
          className="bg-gradient-to-r from-red-600 to-red-500 text-white p-4 rounded-r-lg shadow-lg flex items-center space-x-2 hover:from-red-500 hover:to-red-400 transition-all"
          whileTap={{ scale: 0.95 }}
        >
          <motion.div
            animate={{ 
              y: [0, -4, 0],
              rotate: [-10, 10, -10]
            }}
            transition={{ 
              duration: 1,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            <Bell className="w-6 h-6" />
          </motion.div>
          <span className="font-semibold">Latest Updates</span>
        </motion.button>
      </motion.div>

      {/* Notice Section */}
      <div 
        className="relative bg-white/80 backdrop-blur-md shadow-2xl mx-4 lg:mx-24 rounded-xl overflow-hidden z-20 cursor-pointer hover:bg-white/90 transition-colors"
        style={{ marginTop: '-100px' }}
        onClick={() => navigate('/notices')}
      >
        <div className="container mx-auto">
          <SlidingNotices notices={notices} />
        </div>
      </div>

      {/* Features Section */}
      <section className="py-32">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className={`text-5xl font-bold text-center mb-24 ${
              isDarkMode ? 'text-white' : 'text-gray-800'
            }`}
          >
            {t('Our Features')}
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <FeatureCard
              icon={<BookOpen className="w-12 h-12" />}
              title={t('মানসম্মত শিক্ষা')}
              description={t('আমরা শিক্ষার্থীদের শিক্ষা জীবনে উৎকর্ষ অর্জনে সহায়তা করি।')}
              image={categoryImages.quality_education}
              delay={0.2}
              isDarkMode={isDarkMode}
              color="blue"
            />
            <FeatureCard
              icon={<Users className="w-12 h-12" />}
              title={t('সহপাঠ্যক্রম কার্যক্রম')}
              description={t('আমাদের স্কুল শিক্ষার্থীদের প্রতিভা ও আগ্রহ বিকাশের জন্য বিভিন্ন কার্যক্রম প্রদান করে।')}
              image={categoryImages.extracurricular}
              delay={0.4}
              isDarkMode={isDarkMode}
              color="green"
            />
            <FeatureCard
              icon={<Award className="w-12 h-12" />}
              title={t('অভিজ্ঞ শিক্ষক')}
              description={t('আমাদের নিবেদিত শিক্ষক দল প্রতিটি শিক্ষার্থীর জন্য ব্যক্তিগত মনোযোগ ও নির্দেশনা নিশ্চিত করে।')}
              image={categoryImages.experienced_teachers}
              delay={0.6}
              isDarkMode={isDarkMode}
              color="purple"
            />
          </div>
        </div>
      </section>

      {/* Quick Links */}
      <motion.section 
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        className="py-16 bg-gradient-to-r from-blue-600 to-purple-600"
      >
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <QuickLink icon={<Calendar />} title="Academic Calendar" path="/calendar" />
            <QuickLink icon={<Users />} title="Student Portal" path="/student-portal" />
            <QuickLink icon={<BookOpen />} title="Library" path="/library" />
            <QuickLink icon={<Bell />} title="Announcements" path="/notices" />
          </div>
        </div>
      </motion.section>

      {/* Progress Bar */}
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-600 to-purple-600 origin-left z-50"
        style={{ scaleX: springScrollY }}
      />
    </div>
  );
};

const FeatureCard = ({ icon, title, description, image, delay, isDarkMode, color }) => {
  const [isHovered, setIsHovered] = useState(false);
  
  const colorClasses = {
    blue: 'from-blue-500 to-blue-700',
    green: 'from-green-500 to-green-700',
    purple: 'from-purple-500 to-purple-700'
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className={`rounded-xl overflow-hidden transform transition-all duration-500 ${
        isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'
      } ${isHovered ? 'scale-105 shadow-2xl' : 'shadow-xl'}`}
    >
      <div className="relative h-48 overflow-hidden">
        <motion.img 
          src={image} 
          alt={title}
          className="w-full h-full object-cover"
          animate={{ scale: isHovered ? 1.1 : 1 }}
          transition={{ duration: 0.3 }}
        />
        <div className={`absolute inset-0 bg-gradient-to-t ${colorClasses[color]} opacity-60`} />
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          animate={{ y: isHovered ? 0 : 10, opacity: isHovered ? 1 : 0.8 }}
        >
          <div className="p-4 bg-white/20 backdrop-blur-sm rounded-full">
            {icon}
          </div>
        </motion.div>
      </div>
      
      <div className="p-8">
        <h3 className="text-2xl font-bold mb-4 text-center">
          {title}
        </h3>
        <p className={`text-center ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
          {description}
        </p>
      </div>

      <motion.div
        className="h-1 bg-gradient-to-r from-transparent via-current to-transparent"
        animate={{
          opacity: isHovered ? 1 : 0,
          scaleX: isHovered ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
      />
    </motion.div>
  );
};

const QuickLink = ({ icon, title, path }) => {
  const navigate = useNavigate();
  return (
    <motion.button
      onClick={() => navigate(path)}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="w-full flex items-center space-x-3 bg-white/10 backdrop-blur-md p-4 rounded-lg text-white hover:bg-white/20 transition-colors duration-300"
    >
      {icon}
      <span className="font-medium">{title}</span>
    </motion.button>
  );
};

export default HomePage;
