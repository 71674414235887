// ImageUpload.js
import React, { useState } from 'react';
import { API_BASE_URL } from '../config';
import { motion, AnimatePresence } from 'framer-motion';

const ImageUpload = ({ setMessage }) => {
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleImageUpload = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('category', category);

        const token = localStorage.getItem('adminToken');

        try {
            const response = await fetch(`${API_BASE_URL}/admin/upload_image`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                setMessage('Image uploaded successfully');
                setFile(null);
                setCategory('');
            } else {
                const errorData = await response.json();
                setMessage(`Failed to upload image: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setMessage(`An error occurred: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleImageUpload} className="space-y-6">
            <div className="border-2 border-dashed border-indigo-300 rounded-lg p-6 text-center relative overflow-hidden">
                <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className="hidden"
                    id="file-upload"
                    required
                />
                <label htmlFor="file-upload" className="cursor-pointer flex flex-col items-center space-y-2">
                    <AnimatePresence>
                        {isLoading ? (
                            <motion.div
                                key="spinner"
                                animate={{ rotate: 360 }}
                                transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                            >
                                <svg
                                    className="w-16 h-16 text-indigo-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                                    />
                                </svg>
                            </motion.div>
                        ) : (
                            <motion.div
                                key="upload-icon"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <svg
                                    className="w-16 h-16 text-indigo-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    />
                                </svg>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <span className="text-indigo-600 font-medium">
                        {isLoading ? 'Uploading...' : 'Upload Image'}
                    </span>
                </label>
            </div>
            <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }}>
                <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                    required
                >
                    <option value="">Select a category</option>
                    <option value="logo">Logo</option>
                    <option value="hero">Hero</option>
                    <option value="quality_education">Quality Education</option>
                    <option value="extracurricular">Extracurricular Activities</option>
                    <option value="experienced_teachers">Experienced Teachers</option>
                </select>
            </motion.div>
            <motion.button
                type="submit"
                className="w-full py-3 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
            >
                {isLoading ? 'Uploading...' : 'Upload Image'}
            </motion.button>
        </form>
    );
};

export default ImageUpload;
