import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../config';
import { motion } from 'framer-motion';

const OnlineClassesPage = () => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/get_online_classes`)
      .then((response) => response.json())
      .then((data) => setClasses(data))
      .catch((error) => console.error('Error fetching online classes:', error));
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
          {t('onlineClasses')}
        </h1>
        <div className="space-y-8">
          {classes.map((cls, index) => (
            <motion.div
              key={cls.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-900 mb-2">
                  {cls.title}
                </h2>
                <p className="text-gray-600 mb-4">
                  {new Date(cls.time).toLocaleString()}
                </p>
                <div className="flex space-x-4">
                  {cls.live_link && (
                    <a
                      href={cls.live_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300"
                    >
                      {t('joinLiveClass')}
                    </a>
                  )}
                  {cls.recording_link && (
                    <a
                      href={cls.recording_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300"
                    >
                      {t('watchRecording')}
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnlineClassesPage;
