import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from '../config';

const NoticesPage = () => {
  const { t } = useTranslation();
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/get_notices`)
      .then(response => response.json())
      .then(data => setNotices(data))
      .catch(error => console.error('Error fetching notices:', error));
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-bold text-primary mb-8">{t('schoolNotices')}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {notices.map(notice => (
            <div key={notice.id} className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-2xl font-semibold text-primary mb-2">{notice.title}</h2>
              <p className="text-sm text-gray-600 mb-4">{notice.date}</p>
              <p className="text-gray-800 mb-4">{notice.content}</p>
              {notice.pdf_link && (
                <a 
                  href={notice.pdf_link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="bg-secondary text-white px-4 py-2 rounded-full text-sm font-semibold hover:bg-yellow-500 transition duration-300"
                >
                  {t('viewPDF')}
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoticesPage;