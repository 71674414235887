// ClassManagement.js
import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import EditOnlineClassModal from './EditOnlineClassModal';

const ClassManagement = ({ setMessage }) => {
    const [classTitle, setClassTitle] = useState('');
    const [classTime, setClassTime] = useState('');
    const [classLiveLink, setClassLiveLink] = useState('');
    const [classRecordingLink, setClassRecordingLink] = useState('');
    const [onlineClasses, setOnlineClasses] = useState([]);
    const [editingOnlineClass, setEditingOnlineClass] = useState(null);

    useEffect(() => {
        fetchClasses();
    }, []);

    const fetchClasses = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/get_online_classes`);
            const data = await response.json();
            setOnlineClasses(data);
        } catch (error) {
            console.error('Error fetching classes:', error);
            setMessage('Failed to fetch classes');
        }
    };

    const handleAddOnlineClass = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('adminToken');

        try {
            const response = await fetch(`${API_BASE_URL}/admin/add_online_class`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    title: classTitle,
                    time: classTime,
                    live_link: classLiveLink,
                    recording_link: classRecordingLink,
                }),
            });

            if (response.ok) {
                setMessage('Online class added successfully');
                fetchClasses();
                setClassTitle('');
                setClassTime('');
                setClassLiveLink('');
                setClassRecordingLink('');
            } else {
                const errorData = await response.json();
                setMessage(`Failed to add online class: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setMessage(`An error occurred: ${error.message}`);
        }
    };

    const handleSaveEdit = async (updatedClass) => {
        const token = localStorage.getItem('adminToken');
        const url = `${API_BASE_URL}/admin/edit_online_class/${updatedClass.id}`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedClass),
            });

            if (response.ok) {
                setMessage('Online class updated successfully');
                fetchClasses();
                setEditingOnlineClass(null);
            } else {
                const errorData = await response.json();
                setMessage(`Failed to update class: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error updating class:', error);
            setMessage(`An error occurred while updating class`);
        }
    };

    return (
        <>
            <form onSubmit={handleAddOnlineClass} className="space-y-6">
                <input
                    type="text"
                    value={classTitle}
                    onChange={(e) => setClassTitle(e.target.value)}
                    placeholder="Class Title"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                />
                <input
                    type="datetime-local"
                    value={classTime}
                    onChange={(e) => setClassTime(e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-lg"
                    required
                />
                <input
                    type="url"
                    value={classLiveLink}
                    onChange={(e) => setClassLiveLink(e.target.value)}
                    placeholder="Live Class Link"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                />
                <input
                    type="url"
                    value={classRecordingLink}
                    onChange={(e) => setClassRecordingLink(e.target.value)}
                    placeholder="Recording Link (optional)"
                    className="w-full p-3 border border-gray-300 rounded-lg"
                />
                <button
                    type="submit"
                    className="w-full py-3 bg-indigo-500 text-white rounded-lg"
                >
                    Add Online Class
                </button>
            </form>
            <div className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Existing Online Classes</h2>
                {onlineClasses.map((cls) => (
                    <div key={cls.id} className="border rounded-lg p-4 mb-4">
                        <h3 className="text-xl font-semibold">{cls.title}</h3>
                        <p className="text-gray-600">Time: {new Date(cls.time).toLocaleString()}</p>
                        {cls.live_link && (
                            <a
                                href={cls.live_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                Live Link
                            </a>
                        )}
                        {cls.recording_link && (
                            <a
                                href={cls.recording_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-500 hover:underline"
                            >
                                Recording Link
                            </a>
                        )}
                        <button
                            onClick={() => setEditingOnlineClass(cls)}
                            className="mt-2 px-4 py-2 bg-indigo-500 text-white rounded"
                        >
                            Edit
                        </button>
                    </div>
                ))}
            </div>

            {editingOnlineClass && (
                <EditOnlineClassModal
                    onlineClass={editingOnlineClass}
                    onClose={() => setEditingOnlineClass(null)}
                    onSave={handleSaveEdit}
                />
            )}
        </>
    );
};

export default ClassManagement;
